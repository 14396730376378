




























import defaultComponent from "../utils/defaultComponent";

export default defaultComponent.apply({
  props: ["pathItems"],
  computed: {
    items() {
      const value: any = [];
      const arr =
        this.pathItems ||
        (this.options.content && this.options.content.pathItems);
      let currentPath = "";
      for (const i in arr) {
        const item = arr[i];
        currentPath += "/" + item.path;
        if (parseInt(i, 10) < arr.length - 1) {
          value.push({
            text: item.text,
            to: currentPath,
            exact: true,
          });
        } else {
          value.push({
            text: item.text,
            to: currentPath,
            disabled: true,
          });
        }
      }
      return value;
    },
  },
  extraOptions: {
    defaultClass: "breadcrumbs",
  },
});
